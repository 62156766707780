<template>
  <div>
    <div v-if="!loadingPage" style="display:flex;justify-content:center;min-height:100vh;width:100vw;"  class="landing-wrapper" :class="{ fullscreen: landing && landing.sections.styling.Fullscreen }">
      <Loop v-if="landing" :live="true" :landing="landing" :token="token"/>
      <div v-else class="text-center" style="display: flex;align-items: center;flex-direction: column;justify-content: center;">
        <h1 class="text-danger">404</h1>
        <h4>Page not found</h4>
      </div>
    </div>
    <div v-else class="text-center mt-5">
      <b-spinner></b-spinner>
    </div>
    <b-modal v-if="landing" v-model="showLegalModal" :title="landing.sections.legalFooter[`${selectedPage && selectedPage.name === 'Privacy policy' ? 'Policy' : 'Service' }Label`]" size="lg">
      <!-- eslint-disable vue/no-v-html -->
      <div v-if="selectedPage" v-html="selectedPage.content">
      </div>
      <!--eslint-enable-->
      <template v-slot:modal-footer>
        <b-button variant="light" @click="showLegalModal = false"
          >Close</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import $ from 'jquery'
import Loop from '../Business/Landings/Thanks'

export default {
  components: {
    Loop
  },

  data() {
    return {
      loadingPage: false,
      landing: null,
      loadingSendContact: false,
      contact: {
        name: null,
        month: "",
        day: "",
        phone: null,
        business_name: '',
        website_url: '',
        landing_id: this.$route.params.id,
        business_id: this.$route.params.business 
      },
      pages: [],
      showLegalModal: false,
      selectedPage: null,
      href: location.href,
      token: this.$route.params.token,
    }
  },

  computed: {
    deviceContainerInner() {
      return {
        backgroundColor: this.landing.sections.styling.BackgroundColor,
      }
    },

    containerSectionStyle() {
      return {
        borderRadius: `${this.landing.sections.styling.Roudness}px`,
      }
    },

    logoContainerStyle() {
      return {
        textAlign: this.landing.sections.logo.Align,
        backgroundColor: this.landing.sections.logo.BackgroundColor,
        padding: `${this.landing.sections.logo.Padding}px`,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      }
    },

    legalContainerStyle() {
      return {
        margin: `10px 20px`,
        textAlign: this.landing.sections.legalFooter.Align,
        color: this.landing.sections.legalFooter.Color,
        fontSize: `${this.landing.sections.legalFooter.fontSize}px`,
      }
    },

    contentContainerStyle() {
      let style = {
        paddingBottom: '10px',
        overflow: 'hidden',
      }

      if (!this.landing.sections.styling.Fullscreen) {
        style.backgroundColor = this.landing.sections.main.BackgroundColor
        style.borderRadius = `${this.landing.sections.styling.Roudness}px`
        if (this.activeStep === 'thanks') {
          style.backgroundColor = this.landing.sections.thanks.BackgroundColor
        }
      }

      return style
    },

    landingWidgetStyle() {
      if (this.landing.sections.styling.Fullscreen) {
        return {

        }
      }

      return  {
        padding: '30px 10px',
      }
    },

    landingContainerStyle() {
      let style = {
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: this.landing.sections.styling.BorderColor || 'transparent',
        borderImage: 'initial',
      }

      if (this.landing.sections.styling.Fullscreen) {
        style.backgroundColor = this.landing.sections.main.BackgroundColor
        if (!this.landing.sections.logo.Show)
          style.paddingTop = '30px'
        if (this.activeStep === 'thanks') {
          style.backgroundColor = this.landing.sections.thanks.BackgroundColor
        }
      } else {
        style.maxWidth = `${this.landing.sections.styling.MaxWidth}px`
      }

      return style
    },

    inputGroupStyle() {
      return {
        width: '100%',
        marginBottom: '15px',
      }
    },

    inputStyle() {
      return {
        width: '100%',
        padding: '12px 18px',
        fontSize: `${this.landing.sections.inputs.FontSize}px`,
        borderRadius: `${this.landing.sections.inputs.Roudness}px`,
        border: `1px solid ${this.landing.sections.inputs.BorderColor}`,
        backgroundColor: this.landing.sections.inputs.BackgroundColor,
        color: this.landing.sections.inputs.Color,
      }
    },

    mainContainerStyle() {
      if (!this.landing.sections.styling.Fullscreen) {
        return {
          padding: `${this.landing.sections.main.Padding}px`,
          paddingBottom: `${this.landing.sections.main.Padding}px`,
        }
      } else {
        return {
          maxWidth: `${this.landing.sections.styling.MaxWidth}px`,
          padding: `${this.landing.sections.main.Padding}px`,
          paddingTop: '50px',
          paddingBottom: '0px',
          margin: '0 auto' 
        }
      }
    },

    actionButtonStyle() {
      return {
        backgroundColor: this.landing.sections.actionButton.BackgroundColor,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor:
          this.landing.sections.actionButton.BorderColor || 'transparent',
        color: this.landing.sections.actionButton.Color,
        display: 'block',
        borderRadius: `${this.landing.sections.actionButton.Roudness}px`,
        fontSize: `${this.landing.sections.actionButton.FontSize}px`,
        lineHeight: `38px`,
        padding: `${this.landing.sections.actionButton.Padding}px`,
        width: '100%',
      }
    },

    videoType() {
      let link = this.landing.sections.styling.BackgroundVideo || ""
      if (link.match(/\/\/www\.youtube\.com/)) {
        return 'youtube'
      } else if(link.match(/\/\/vimeo.com/)) {
        return 'vimeo'
      }

      return 'video'
    },

    videoSource() {
      let link = this.landing.sections.styling.BackgroundVideo || ""

      if (this.videoType === 'youtube') {
        let videoId = link.replace(/.*\?v=/, '').replace(/&.*/, '')
        return `https://www.youtube.com/embed/${videoId}?controls=0&amp;autoplay=1&amp;mute=1&amp;loop=1`
      } else if(this.videoType === 'vimeo'){
        let videoId = link.replace(/.*com\//, '')
        return `https://player.vimeo.com/video/${videoId}?title=0&amp;byline=0&amp;portrait=0&amp;autoplay=1&amp;loop=1&amp;muted=1&amp;background=1`
      }

      return link.replace(/(https|http)/, 'https')
    },
  },

  mounted() {
    this.getPage()
    document.body.style.paddingBottom = 0
  },

  methods: {
    getFullLink(link)
    {
      if (link && !link.startsWith('http'))
      {
        return 'https://' + link
      }
      return link
    },

    getPage() {
      this.loadingPage = true

      this.$store.dispatch('landing/findRewardByToken', this.$route.params.token)
        .then(({landing, pages }) => {
          this.landing = landing
          this.loadingPage = false
          this.addCustomStyle()
          this.addScript()
        })
        .catch((err) => {
          console.log(err)
          this.loadingPage = false
        })
    },

    sendContact() {
      this.loadingSendContact = true

      this.$store.dispatch('landing/sendContact', this.contact)
        .then((res) => {
          if (this.landing.sections.thanks.IsRedirect && this.landing.sections.thanks.RedirectLink) {
            let redirect = this.landing.sections.thanks.RedirectLink

            if (!this.landing.sections.thanks.RedirectLink.startsWith('http')) {
              redirect = 'https://' + this.landing.sections.thanks.RedirectLink
            }
            location.href = redirect
          } else {
            this.loadingSendContact = false
            if (this.landing.type === 'loop') {
              this.token = res.reward.token
              this.activeStep = 'loop'
            } else {
              this.activeStep = 'thanks'
            }
            
            this.contact = {
              ...this.contact,
              name: null,
              month: "",
              day: "",
              phone: null,
              business_name: '',
              website_url: '',
            }
          }
        })
        .catch(() => {
          this.loadingSendContact = false
        })
    },

    selectStep(step) {
      this.activeStep = step
    },

    addCustomStyle() {
      document.getElementById('landing-css') && document.getElementById('landing-css').remove()

      let style = document.createElement('style');
      style.type = 'text/css';
      style.id = 'landing-css'
      style.innerHTML = `
        .landing-container .vti__dropdown {
          background-color: ${this.landing.sections.inputs.BackgroundColor};
          border-radius: ${this.landing.sections.inputs.Roudness}px;
        }
        .landing-container .vti__input {
          padding: 12px 20px 12px 5px;
          height: initial;
          font-size: ${this.landing.sections.inputs.FontSize}px;
          color: ${this.landing.sections.inputs.Color};
          border-radius: ${this.landing.sections.inputs.Roudness}px;
          background-color: ${this.landing.sections.inputs.BackgroundColor};
        }
        .landing-container .vue-tel-input {
          border-color: ${this.landing.sections.inputs.BorderColor};
          border-radius: ${this.landing.sections.inputs.Roudness}px;
          background-color: ${this.landing.sections.inputs.BackgroundColor};
        }`
      document.querySelector('head').append(style)
    },

    addScript() {
      let scripts = ['Custom']

      for(let script of scripts) {
        if (this.landing.sections.scripts[script]) {
          let content = this.landing.sections.scripts[script]

          let scriptUrls = [];
          content = content.replace(/<script([^>]*src[^>]*)>([^]*?)??<\/script>/gi, (match, attr, content) => {
            let src = attr.match(/http.*"/i)
            if (src)
            {
              src = src[0].trim().replace(/['"]+/g, '')
              scriptUrls.push(new Promise((resolve, reject) => {
                $.getScript(src)
                  .done(() => { resolve() })
                  .fail(() => { resolve() })
              }))
            }
            return ''
          });

          Promise.all(scriptUrls).then(() => {
            $(content).appendTo(document.body)
          })
        }
        
      }
    },

    openLegal(page) {
      this.showLegalModal = true
      this.selectedPage = this.pages[page]
    },
  },
}
</script>

<style lang="scss" scoped>
.social-box {
  
  a {
    font-size: 50px;
  }
  .uil-facebook {
    color: #4267B2;
  }
  .uil-twitter {
    margin-left: 20px;
    color: #1DA1F2;
  }
}
.fullscreen {
  .landing-container {
    flex: 1 1 auto;
    border-radius: 0px !important;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }
}
.device-container {
  min-width: 100%;
  display: flex;
  justify-content: center;
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
  z-index: 0;
}

.device-container-inner {
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
}

.landing-widget {
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.videoContainer {
  video, iframe {
    transform: translateX(-50%) translateY(-50%);
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -1;
  }
}

.input-group span {
  width: 100%;
}

.landing-widget {
  .main-container {
    width: 100% !important;
  }
}

.fullscreen {
  .landing-widget {
    align-items: inherit;
  }
}

.legal-container {
  .privacy-link {
    cursor: pointer;
    &:hover {
      text-decoration: underline!important;
    }
  } ;
}

.powered-by-content {
  padding-right: 10px;
  display: block;
  a {
    color: inherit;
    font-weight: bold;
    &:hover {
      text-decoration: underline!important;
    };
  }
}

@media screen and (max-width: 768px) {
  .landing-wrapper {
    &:not(.fullscreen) {
      .landing-widget {
        padding: 10px !important;
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>
